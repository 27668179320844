import React from "react";
import styled from "styled-components";

export const MDWrapper = styled.div`
    color: rgba(39, 39, 42, 1);
    h2 {
      font-size: 16px;
        font-weight: 700;
    margin-bottom: 2px;
    }
    h1 {
      font-size: 18px;
        font-weight: 700;
    margin-bottom: 12px;
    }
    p, ul {
    margin-bottom: 12px;
    }
    a {
           color: #2196f3;
           border-bottom: 1px solid;
               text-decoration: auto;
    }
`;

export function PoliciesMDWrapper({children}) {

    return <MDWrapper className="prose prose-base max-w-screen-md	">
        {children}
    </MDWrapper>
}